import type { DS_FooterSheinInfo } from '../../../types'

// 获取底部 sheinInfo 信息
export const footerInfoFetch: DS_FooterSheinInfo.APIS_THIS['footerInfoFetch'] = async function (
  dataType: string,
  channelId: string | number,
) {
  const result = await this.$schttp<Object>({
    url: '/ccc/bottom/policy',
    params: {
      sceneKey: dataType,
      channel_id: channelId,
    },
  })
  return result.data
}
