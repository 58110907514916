<template>
  <div
    v-if="Object.keys(sheinFotterInfo).length"
    class="bsc-footer-sheininfo"
    :da-expose-code="frozenAnalysisInfo.exposeCode"
  >
    <p
      v-if="
        titleRequired &&
          sheinFotterInfo &&
          !!sheinFotterInfo['titleList'].length
      "
      class="bsc-footer-sheininfo__title"
      :class="{ active: true }"
      tabindex="0"
      role="text"
      :aria-label="sheinFotterInfo['titleGroup']"
      :data-eventcategory="frozenAnalysisInfo.eventCategory"
      da-event-click="2-21-2"
      @tap="sliderEvent"
    >
      {{ sheinFotterInfo['titleGroup'] }}
      <Icon
        v-if="arrow == 'title'"
        name="sui_icon_more_down_16px"
        size="16px"
        class="iconfont"
        color="#969696"
        :style="[{ lineHeight: '1.16rem',position: 'absolute'}, isCssRight ? { left: '0' } : { right: '0' }]"
      />
    </p>
    <div
      v-if="sheinFotterInfo['titleList']"
      class="bsc-footer-sheininfo__content j-expose__footer-sheininfo"
      :class="{
        active: true,
        'bsc-footer-sheininfo__content-home': dataType == 'home_bottom',
      }"
      @transitionend="transitionend"
      @webkitTransitionEnd="transitionend"
    >
      <template v-for="(item, index) in sheinFotterInfo['titleList']">
        <a
          v-if="dataType == 'home_bottom' || index < 6"
          :key="index"
          v-expose="{
            id: 'expose_privacy_info.comp_footer-shein-info',
            data: { dataSeat: `${index + 1}'${item.title}` },
            code:'home_bottom-shein-info',
          }"
          v-tap="{
            id: 'click_privacy_info.comp_footer-shein-info',
            data: { dataSeat: `${index + 1}'${item.title}` },
          }"
          :style="dataType == 'category_bottom' && { height: '0.96rem', lineHeight: '0.96rem' }"
          class="bsc-footer-sheininfo__content__link j-expose__footer-sheininfo__link"
          :data-eventcategory="frozenAnalysisInfo.eventCategory"
          :data-expose-id="`footer-sheininfo-${index + 1}-${item.title}`"
          href="javascript:;"
          tabindex="0"
          :aria-label="item.title"
          role="link"
          @keydown.enter.prevent="
            linkToArticle(
              item.jumpType == 1
                ? `${url}/${item.article.relativeUrl}-a-${item.value}.html`
                : item.value,
            )
          "
          @click="
            linkToArticle(
              item.jumpType == 1
                ? `${url}/${item.article.relativeUrl}-a-${item.value}.html`
                : item.value,
            )
          "
        >
          <div
            class="j-expose__footer-sheininfo__link-con"
            style="height: 100%"
          >
            {{ item.title }}
            <Icon
              v-if="arrow == 'content'"
              name="sui_icon_more_right_16px_2"
              size="16px"
              color="#969696"
              :is-rotate="isCssRight"
              :style="[{ lineHeight: '1.34rem', position: 'absolute' }, isCssRight ? { left: '0' } : { right: '0' }]"
              class="iconfont"
            />
          </div>
        </a>
      </template>
      <a
        v-if="dataType == 'category_bottom'"
        href="javascript:;"
        @click="linkToArticle(`${url}/Privacy-Center-a-1045.html`)"
      >{{ privacyLanguage }}</a
      >
      <a
        v-if="(isSuportOneTrust || isSupportCookieBanner) && dataType == 'category_bottom'"
        href="javascript: void(0);"
        @click="handleOneTrustMangeCookie"
      >{{ cookieLanguage }}</a
      >
    </div>
  </div>
</template>

<script name="BFooterSheinInfo" setup lang="ts">
import { ref, toRefs, onMounted, nextTick, watch, computed, shallowRef } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import {useAnalysis} from '../../common/analysisSource'

// 全局配置
const appConfigs = useAppConfigs()
const langPath = appConfigs.$envs.langPath

const emit = defineEmits(['onetrustClick', 'cookieBannerClick'])

const props = defineProps({
  fsData: {
    type: Object,
    default: () => ({}),
  },
  dataSource: {
    type: Object,
    default: () => ({}),
  },
  analysisSource: {
    type: Object,
    default: () => ({}),
  },
  titleRequired: {
    type: Boolean,
    required: true,
  },
  arrow: {
    type: String,
    default: 'top',
  },
  transitionend: {
    type: Function,
    default: () => {},
  },
  dataType: {
    type: String,
    required: true,
    default: 'home_bottom',
  },
  analysisInfo: {
    type: Object,
    default: () => ({
      eventCategory: '',
      exposeCode: '',
    }),
  },
  channelId: {
    type: [String, Number],
    default: '',
  },
  isSuportOneTrust: {
    type: Boolean,
    default: false,
  },
  isSupportCookieBanner: {
    type: Boolean,
    default: false,
  },
  privacyCookieSdk: {
    type: Object,
    default: () => {},
  },
  oneTrust: {
    type: Object,
    default: () => {},
  },
  language: {
    type: Object,
    default: () => {},
  },
})

const {
  titleRequired,
  arrow,
  transitionend,
  dataType,
  channelId,
} = toRefs(props)


const { vTap, vExpose } = useAnalysis(props.analysisSource)

const url = ref('')
const hasTab = ref(false)
const backupMarginTop = ref('')
const isInitFixed = ref(false)
const sheinFotterInfo = ref({})
const frozenAnalysisInfo = shallowRef({ ...props.analysisInfo })

const isCssRight = computed(() => appConfigs.$envs.cssRight)
const cookieLanguage = computed(() => {
  const isUsSite = ['pwus'].includes(appConfigs.$envs.siteUID)
  return isUsSite
    ? props.language?.SHEIN_KEY_PWA_19007
    : props.language?.SHEIN_KEY_PWA_18057
})
const privacyLanguage = computed(() => props.language?.SHEIN_KEY_PWA_18362)

// 数据源：
const apis = getSource({}, appConfigs)
// 数据源：END

async function getSheinInfo() {
  apis.footerInfoFetch(dataType.value, channelId.value).then(res => {
    sheinFotterInfo.value = res?.info?.content?.content?.props?.items[0] || {}
  })
  nextTick(() => {
    transitionend.value()
  })
}

function sliderEvent() {
  if (arrow.value !== 'title') return
  if (hasTab.value) return
  hasTab.value = true
  setTimeout(() => {
    hasTab.value = false
  }, 500)
}

function linkToArticle(link) {
  if (hasTab.value) return
  hasTab.value = true
  window.location.href = link
  hasTab.value = false
}

function handleOneTrustMangeCookie() {
  if (props.isSupportCookieBanner) {
    props.privacyCookieSdk?.openPreference()
    emit('cookieBannerClick')
    return
  }
  emit('onetrustClick')
  nextTick(() => {
    props.oneTrust.ToggleInfoDisplay()
    backupMarginTop.value = document.body.style.marginTop
    onetrustIosFix()
  })
}

function onetrustIosFix() {
  if (window.onetrustBannerLoadedError) return
  if (isInitFixed.value) return
  if (backupMarginTop.value === '' || backupMarginTop.value === '0px') return
  const ua = navigator.userAgent
  if (!/iphone|ipad|ipod/i.test(ua)) return
  isInitFixed.value = true

  const targetNode = document.getElementById('onetrust-pc-sdk') as HTMLElement
  const config = { attributes: true, childList: false, subtree: false }

  const callback = mutationsList => {
    for (const mutation of mutationsList) {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'style'
      ) {
        if ((targetNode as HTMLElement).style.display === 'none') {
          return fixBranchBannerAtrribute(backupMarginTop.value)
        }
        fixBranchBannerAtrribute(0)
      }
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)
}

function fixBranchBannerAtrribute(v) {
  document.body.style.marginTop = v
}

watch(channelId, () => {
  getSheinInfo()
})

onMounted(() => {
  getSheinInfo()
  url.value = `${location.origin}${langPath}`
})
</script>

<style lang="less">
.bsc-footer-sheininfo {
  &__title {
    position: relative;
    height: 0.96rem;
    line-height: 0.96rem;
    font-weight: bold;
    font-size: 0.3733rem;
    color: #222;
    [class*='iconfont'] {
      transition: all 0.3s;
    }
  }
  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    &.active {
      max-height: 11.5rem;
    }
    &.bsc-footer-sheininfo__content-home {
      max-height: initial;
    }
    a {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 1.17rem;
      line-height: 1.17rem;
      padding: 0;
      font-size: 0.3733rem;
      color: #222;
      overflow: hidden;
      text-decoration: none;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.vertical {
    .bsc-footer-sheininfo__content {
      text-align: center;
    }
    a {
      padding: 0 0.32rem;
      width: auto;
      font-size: 0.32rem;
    }
  }
}
</style>
