import type { AS_FooterSheinInfo } from '../../../types'

const standard: AS_FooterSheinInfo.AnalysisFuncs = {
  'click_privacy_info.comp_footer-shein-info': ({ extraData }) => {
    return {
      content_list: extraData.dataSeat,
      privacy: 1,
    }
  },
  // 聚合上报
  'expose_privacy_info.comp_footer-shein-info': ({ source }) => {
    const dataList = source.bindData?.map(item => item.dataSeat)
    const content_list = dataList.join(',')
    return {
      content_list,
      privacy: 1,
    }
  },
}

export default standard
