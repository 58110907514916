import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BFooterSheinInfo } from './footer-shein-info.vue'
import type { DS_FooterSheinInfo } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getFooterSheinInfoFsDataRunner(dataSource?: DS_FooterSheinInfo.DataSource) {
  return getFsDataRunner(dataSource, useAppConfigs())
}
